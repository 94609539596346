<template>
  <div>
    <authentication></authentication>

    <div class="beititles"><img src="../../../img/batter.png" alt="" /></div>
    <!-- <div class="banner">
        <div class="banner-img">  -->
    <!-- <div class="banner-zi">企业验证</div> -->
    <!-- <img src="../../../img/xw.png" alt="" />
        </div>
      </div> -->

    <div class="box">
      <div class="topic-box">
        <div class="reli">
          <div class="titles">{{ titlel }}</div>
        </div>
        <!-- <div class="title-box"  v-if="hide==0" >
            <div class="box-zi">
              <div @click="golist(tree)">{{ tree.title }}</div>
              <div class="zier" @click="golist(tree)">
                {{ tree.intro }}
              </div>
            </div>
            <img @click="golist(tree)" :src="tree.litimg" alt="" />
          </div> -->
        <template v-for="(item, index) in list">
          <!-- <div class="title-box" v-if="pageNo == 1 && index == 0" :key="index">
            <div class="box-zi">
              <div @click="golist(item)">{{ item.title }}</div>
              <div class="zier" @click="golist(item)">
                {{ item.intro }}
              </div>
            </div>
            <img
              style="object-fit: cover"
              @click="golist(item)"
              :src="item.litimg"
              alt=""
            />
          </div> -->
          <!-- v-if="index != 0 || pageNo != 1" -->
          <div
            :key="index"
            class="list"
            @click="golist(item)"
          >
            <div class="xiang">
              <!-- <img style="object-fit: cover;"  alt="" :src="item.litimg" /> -->

              <div class="calendar">
                <span class="dec">{{item.publishTime ? getPTime(item.publishTime) : ''}}</span>
                <img src="../../../img/relii.png" alt="" />
                <div class="zt">{{item.publishTime ? getPtTime(item.publishTime) : ''}}</div>
              </div>
              <div class="r-content">
                <div class="titles">
                <div>
                  {{ item.title }}
                </div>
                <div class="author">
                  {{ item.intro }}
                </div>
                <!-- <div class="publishTime">
                  {{
                    item.publishTime.substring(0, item.publishTime.indexOf(' '))
                  }}
                </div> -->
              </div>
              <div class="r-img" v-if="item.newsType == '2'">
                <img class="lit-img" style="object-fit: cover;"  alt="" :src="$imgUrl(item.litimg)" />
              </div>
              </div>
            </div>
          </div>
        </template>

        <!-- <div
            v-for="(item, index) in list"
            :key="index"
            class="list"
            @click="golist(item)"
          >
            <template v-if="page.currentPage !== 1">
              <div class="xiang">
                <img :src="item.litimg" alt="" />
                <div class="titles">
                  <div>
                    {{ item.title }}
                  </div>
                  <div class="author">
                    {{ item.intro }}
                  </div>
                  <div class="publishTime">
                    {{
                      item.publishTime.substring(
                        0,
                        item.publishTime.indexOf(' ')
                      )
                    }}
                  </div>
                </div>
              </div>
            </template>
          </div> -->
      </div>
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="chengePagination"
      >
      </el-pagination>
      <!-- <el-pagination
          class="ye"
          :background="true"
          layout="prev, pager, next"
          :current-page="page.currentPage"
          :page-sizes="page.pageSizes"
          :page-size="page.size"
          :total="page.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination> -->
    </div>

    <Foots></Foots>
  </div>
</template>

<script>
import mixin from '../../../api/mixin/mixin'
import authentication from '../../../components/hometitle/hometitle.vue'
import Foots from '@/components/foot/foot.vue'
import { detail, jobNewsColumnTree, jobNews } from '../../../api/home/home'
export default {
  mixins: [mixin],
  data() {
    return {
      id: '',
      newsList: [],
      list: {},
      source: {},
      imgUrl: '',
      jobNewsColumnList: [], // 新闻栏目
      tree: [],
      titlel: [],
      categoryList: [],
      hor: [],
      sort: [],
      hide: 0,
      total: 1,
      pageNo: 1,
    }
  },
  components: {
    authentication,
    Foots,
  },
  computed: {},
  created() {
    this.id = this.$route.query.id
    jobNewsColumnTree({ id: this.$route.query.id }).then((res) => {
      this.titlel = res.data[0].title
      // this.id = res.data[0].id
    })
    this.init()
  },
  mounted() {},
  methods: {
    init() {
      // console.log(this.page.currentPage, 'this.page.currentPage')
      // jobNews({
      // pageNo: this.page.currentPage,
      // pageSize: this.page.size,
      // jobNewsColumnId: this.id
      // }).then((res) => {
      // this.list = res.data.rows
      // this.total = res.data.totalRows
      // // 总页数
      // if (res.success) {
      //   this.page.total = res.data.totalRows
      // }
      // })

      jobNews({
        jobNewsColumnId: this.id,
        pageNo: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        console.log(res, '------------')
        this.list = res.data.rows
        this.total = res.data.totalRows
        console.log(this.total)
      })
    },
    chengePagination(cur) {
      this.pageNo = cur
      jobNews({
        pageNo: cur,
        pageSize: 10,
        jobNewsColumnId: this.id,
      }).then((res) => {
        this.list = res.data.rows
        this.total = res.data.totalRows
      })
    },

   //月
   getPTime(val) {
				  var timearr = val.replace(' ', ':').replace(/\:/g, '-').split('-')
				  var timestr = Number(timearr[0]);

				return timestr;
			},

   //日
   getPtTime(val) {
				  var timearr = val.replace(' ', ':').replace(/\:/g, '-').split('-')
				  var timestr = timearr[1] + '月'+timearr[2] + '日';
				return timestr;
			},

    golist(item) {
      if(item.newsType == '1' && item.target){
        if(item.openMode == "_blank"){
          window.open(item.target, '_blank')
        }else{
          window.location.href = item.target;
        }
      } else {
        this.$router.push({
	        name: "content",
	        query:{
	          id: item.id
	        }
        });
      }
    },
  },
}
</script>

<style scoped lang="scss">
.beititles {
  margin-top: 80px;
  width: 100%;
  height: 220px;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.r-content{
  width: 100%;
  display: flex;
  justify-content: space-between;
  .r-img{
    width: 120px;
    height: 120px;
    margin: 0 20px;
    .lit-img{
      width: 120px;
      height: 120px;
    }
  }
}

// 中间内容
.box {
  width: 1200px;
  margin: 58px auto;
}
.topic-box {
  width: 1200px;
  min-height: 1500px;
  padding-bottom: 50px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  margin: 58px auto;
  padding: 50px 20px 50px 0;
  .title-box {
    display: flex;
    margin-left: 50px;
    width: 100%;
    height: 300px;
    .box-zi {
      width: 408px;
      height: 72px;
      color: rgba(51, 51, 51, 1);
      font-size: 24px;
      margin-top: 25px;
      .zier {
        color: rgba(102, 102, 102, 1);
        height: 194px;
        font-size: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 12px;
        // white-space: nowrap;
      }
    }
    img {
      width: 660px;
      height: 250px;
      display: block;
      margin-left: 41px;
    }
  }
  .reli {
    display: block;
    width: 247px;
    background: url(../../../img/renmen.png) repeat;
    background-size: 100% 100%;
    height: 73px;
    .titles {
      margin-left: 63px;
      font-size: 30px;
    }
  }
  .newsList {
    width: 1100px;
    height: 100%;
    margin: 0 auto;
  }
}
.list {
  cursor: pointer;
  margin-left: 50px;
  margin-top: 25px;
}
// 分页
.ye {
  margin-left: 554px;
  margin-top: 65px;
}
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}
// 全部新闻
.fesl {
  display: flex;
}
// 循环
.titles {
  margin-left: 37px;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  .author {
    margin-top: 20px;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    // width: 934px;
    height: 73px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .publishTime {
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
  }
}

.listt {
  margin-left: 50px;
}
.xiang {
  display: flex;
  .calendar {
    position: relative;
    text-align: center;
    .dec {
      font-size: 22px;
      color: #fff;
      width: 100%;
      padding-top: 5px;
      position: absolute;
    }
    img  {
      width: 70px;
      height: 70px;
    }
    .zt {
      font-size: 15px;
      margin-top:-30px;
      color:#4B77FF;
    }
  }
}
</style>
