
export default {
  data() {
    return {
      page: {
        currentPage: 1, // 页
        size: 2, // 每页数量
        total: 0,
        pageSizes: [10, 20, 30, 40]
      },
    
    }
  },
 
  methods: {
    handleSizeChange(res) {
      this.page.size = res
      this.init()
    },
    handleCurrentChange(res) {
      this.page.currentPage = res
      this.init()
    }
  }
}

